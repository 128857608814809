import { axiosInstance } from "~/api";
import { ExchangeRatesResponse, MarketPricesResponse } from "types/crypto-exchange";

class CryptoExchangeService {
  private async fetchRates<T>(
    endpoint: string,
    method: "GET" | "POST",
    data: any | null = null
  ): Promise<MarketPricesResponse> {
    return axiosInstance({
      url: `/exchange-rate-http/exchange-rates/global/${endpoint}`,
      method,
      data,
    });
  }
  private async tiggerCalculation<T>(
    endpoint: string,
    method: "GET" | "POST",
    data: any | null = null
  ): Promise<ExchangeRatesResponse> {
    return axiosInstance({
      url: `/exchange-rate-http/exchange-rates/global/${endpoint}`,
      method,
      data,
    });
  }
  

  async getMarketRates() {
    return this.fetchRates(
      "calculator/boot",
      "GET"
    );
  }

  async calculateRatesWhenAssetSourceChanges({
    amount = 1,
    sourceAsset,
    destinationAsset,
  }: {
    amount?: number;
    sourceAsset: string | undefined;
    destinationAsset: string | undefined;
  }) {
    return this.tiggerCalculation(
      `swap-test?amount=${amount}&sourceAsset=${sourceAsset}&destinationAsset=${destinationAsset}`,
      "GET"
    );
  }
  async calculateRatesWhenAssetAmountChanges({
    amount,
    sourceAsset,
    destinationAsset,
  }: {
    amount?: string | number;
    sourceAsset: string | undefined;
    destinationAsset: string | undefined;
  }) {
    return this.tiggerCalculation(
      `swap-test?amount=${amount}&sourceAsset=${sourceAsset}&destinationAsset=${destinationAsset}`,
      "GET"
    );
  }
}

export default new CryptoExchangeService();
